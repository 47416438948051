import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Link,
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Button,
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const [open, setOpen] = useState(false);
  const [lastSend, setLast] = useState({});

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('O e-mail deve ser um endereço de e-mail válido').required('Campo obrigatório'),
    password: Yup.string().required('Campo obrigatório'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password, data?.forceLogout);
    } catch (error) {
      if (error?.type === 'sessionOngoing') {
        setLast({ email: data.email, password: data.password, forceLogout: true });
        setOpen(true);
      } else {
        reset();
        if (isMountedRef.current) {
          setError('afterSubmit', error);
        }
      }
    }
  };

  const reSend = () => {
    onSubmit(lastSend);
  };

  const closeModel = () => {
    setLast({});
    setOpen(false);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="E-mail" />

        <RHFTextField
          name="password"
          label="Senha"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Lembrar" />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Esqueceu a senha?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>

      <Dialog fullWidth maxWidth="sm" open={open} onClose={closeModel}>
        <DialogTitle>Usuário já conectado!</DialogTitle>

        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Você já está autenticado em outra sessão, deseja encerrar a conexão anterior?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" onClick={reSend}>
            Sim
          </Button>
          <Button color="inherit" variant="outlined" onClick={closeModel}>
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
