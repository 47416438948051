import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import chatReducer from './slices/chat';
import dashboardReducer from './slices/dashboard';
import onlineReducer from './slices/online';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  chat: chatReducer,
  online: onlineReducer,
});

export { rootPersistConfig, rootReducer };
