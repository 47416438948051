import { useContext } from 'react';
//
import { AuthContext } from '../contexts/JWTContext';

// ----------------------------------------------------------------------

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('O contexto de autenticação deve ser usado dentro do AuthProvider');

  return context;
};

export default useAuth;
