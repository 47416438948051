import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import ReportCompleteSnackbar from '../components/QrcodeStick';

// add a <div> child to body under which to mount the snackbars
const mountPoint = document.createElement('div');
document.body.appendChild(mountPoint);

export default {
  success(msg, maxSnacks = 3) {
    this.toast(msg, 'success', maxSnacks);
  },
  warning(msg) {
    this.toast(msg, 'warning');
  },
  info(msg) {
    this.toast(msg, 'info');
  },
  error(msg, maxSnacks = 3) {
    this.toast(msg, 'error', maxSnacks);
  },
  callqrcode(name, msg) {
    this.qrcode(name, msg, 'reportComplete');
  },

  toast(msg, variant = 'default', maxSnacks = 3) {
    const ShowSnackbar = ({ message }) => {
      const { enqueueSnackbar } = useSnackbar();
      enqueueSnackbar(message, { variant });
      return null;
    };
    ReactDOM.render(
      // see https://github.com/iamhosseindhv/notistack#snackbarprovider
      <SnackbarProvider
        maxSnack={maxSnacks}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        Components={{
          reportComplete: ReportCompleteSnackbar,
        }}
      >
        <ShowSnackbar message={msg} variant={variant} />
      </SnackbarProvider>,
      mountPoint
    );
  },

  qrcode(name, msg, variant = 'default') {
    const ShowSnackbar = ({ message }) => {
      const { enqueueSnackbar } = useSnackbar();
      enqueueSnackbar(message, { variant, persist: true, nameService: name });
      return null;
    };
    ReactDOM.render(
      // see https://github.com/iamhosseindhv/notistack#snackbarprovider
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        Components={{
          reportComplete: ReportCompleteSnackbar,
        }}
      >
        <ShowSnackbar message={msg} variant={variant} />
      </SnackbarProvider>,
      mountPoint
    );
  },
};
