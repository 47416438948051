import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
// Auth
import useAuth from '../../../hooks/useAuth';
//
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
  const { user } = useAuth();
  const [showBiSection, setShowBiSection] = useState(false);

  useEffect(() => {
    // Agents shouldn't see the BI tab, ever.
    if (user?.acl?.profile === 'agent') {
      return;
    }

    const companyId = user?.companyId;

    // Missing required data.
    if (!companyId) {
      return;
    }

    // Other users should see the BI tab only if the company has a report URL
    // configured.
    axios
      .get(`/api/company-manager/${companyId}/looker-studio-url`)
      .then(async (response) => {
        const json = response.data;
        if (json && json.lookerStudioReportUrl) {
          setShowBiSection(true);
        }
      })
      .catch(console.error);
  }, [user]);

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <>
            {group?.role.includes(user?.acl.profile) && (
              <>
                <ListSubheaderStyle
                  sx={{
                    ...(isCollapse && {
                      opacity: 0,
                    }),
                  }}
                >
                  {group.subheader}
                </ListSubheaderStyle>

                {group.items
                  .map((list) => {
                    if (list.title === 'BI' && !showBiSection) {
                      return undefined;
                    }
                    if (list.title === 'Online' && user.acl.profile === 'agent') {
                      return undefined;
                    }

                    return <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />;
                  })
                  .filter((x) => !!x)}
              </>
            )}
          </>
        </List>
      ))}
    </Box>
  );
}
