import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  groups: [],
  last7Days: {},
  now: {},
  isLoading: false,
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DASHBOARD INFOMRATION
    getDashboardSuccess(state, action) {
      const dashInfo = action.payload;
      state.isLoading = false;
      state.groups = dashInfo.groups;
      state.last7Days = dashInfo.last7Days;
      state.now = dashInfo.now;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

function debounce(func, delay) {
  let timeoutId;
  return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

export function getDashboard(companyId) {
  return debounce(async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/dashboard/${companyId}`);
      dispatch(slice.actions.getDashboardSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }, 1000);
}
