import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// Store
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  groups: [],
  total: 0,
};

const slice = createSlice({
  name: 'online',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    OnlineUsers(state, action) {
      state.users = action.payload.users;
      state.groups = action.payload.groups;
      state.isLoading = false;
    },
    UpdateChats(state, action) {
      const { agentId } = action.payload;

      state.users = state.users.map((user) => {
        if (user._id === agentId) {
          let updatedChatCount = user.ongoingChatCount;

          updatedChatCount += 1;

          return {
            ...user,
            ongoingChatCount: updatedChatCount,
          };
        }
        return user;
      });
    },
    RemoveChats(state, action) {
      const { agentId } = action.payload;

      state.users = state.users.map((user) => {
        if (user._id === agentId) {
          let updatedChatCount = user.ongoingChatCount;

          updatedChatCount -= 1;

          return {
            ...user,
            ongoingChatCount: updatedChatCount,
          };
        }
        return user;
      });
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { UpdateChats, RemoveChats } = slice.actions;

export function getOnlineUsers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/online');
      dispatch(slice.actions.OnlineUsers(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
