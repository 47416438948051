import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function telMask(num) {
  if (num === undefined) {
    return num;
  }    
  if (num.length > 13) {
    let str = num.replace(/\D+/g, '').toString();
    str = str.replace(/(\d{2})(\d{1})(\d)/, '($1) $2$3 ').replace(/(\d{5})(\d)/, '$1-$2');
    return str;
  }

  // if normal phone number
  let str = num.replace(/\D+/g, '').substr(0, 11).toString();
  if (str.length === 11) {
    str = str.replace(/(\d{2})(\d{1})(\d)/, '($1) $2 $3').replace(/(\d{4})(\d)/, '$1-$2');
  } else {
    str = str.replace(/(\d{2})(\d)/, '($1) $2').replace(/(\d{4})(\d)/, '$1-$2');
  }
  return str;
}

export function cpfMask(num) {
  let str = num.replace(/\D+/g, '').substr(0, 11).toString();
  if (str.length <= 11) {
    str = str
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2');
  }
  return str;
}

export function cnpjMask(num) {
  let str = num.replace(/\D+/g, '').substr(0, 14).toString();
  if (str.length <= 14) {
    str = str
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  }
  return str;
}
