import { useEffect, useContext, useState } from 'react';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// redux
import { useDispatch } from './redux/store';
import useAuth from './hooks/useAuth';

// components
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

// utils
import { OnConnectCompany } from './utils/socketControl';
import { SocketContext } from './contexts/SocketIo';

// ----------------------------------------------------------------------

export default function App() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [socketUse, setSocketUse] = useState(false);

  useEffect(() => {
    // Read socket msg in all application

    if (user !== null && !socketUse) {
      socket.on(user.companyId, (msg) => {
        OnConnectCompany(msg, user, dispatch);
      });
      // Validade socket to use true, so it ill work just one time and dont be in loop in every user change
      setSocketUse(true);
    }
  }, [dispatch, user, socket, socketUse]);

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                <ScrollToTop />
                <Router />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
