import { createContext, useEffect, useReducer, useContext, useState } from 'react';
import PropTypes from 'prop-types';

// utils
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';
import { SocketContext } from './SocketIo';
import { SOCKET_URL } from '../config';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  update: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const socket = useContext(SocketContext);
  const [socketForce, setForce] = useState({ v: false, token: null, user: {} });

  // Starts every time you enter the page
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const userId = window.localStorage.getItem('usr_id');

        if (accessToken && userId && accessToken !== undefined) {
          setSession(accessToken);

          const response = await axios.get(`/api/user/${userId}`);
          const user = response.data;

          // CheckCompany
          const companyRes = await axios.get(`/api/company/${user.companyId}`);

          if (companyRes.status) {
            user.company = companyRes.data;
          }
          // CheckService
          const serviceRes = await axios.post(`/api/service/query/`, {
            query: { companyId: user.companyId },
            projection: { description: 1, type: 1, _id: 1 },
          });

          if (serviceRes.status) {
            user.services = serviceRes.data;
          }

          // Init Global Socket
          socket.connect(`${SOCKET_URL}`, {
            auth: { token: accessToken },
            withCredentials: true,
            autoConnect: true,
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
          });
          // Connect to Company to recive mensages
          socket.on('connect', async () => {
            socket.emit('subscribe', user.companyId);
          });
          socket.on('disconnect', async (reason) => {
            // Only try reconnecting if we got disconnected by the _server_. If
            // we disconnected ourselves, we don't want to reconnect.
            if (reason !== 'io server disconnect') {
              return;
            }

            const attemptReconnect = async () => {
              // Wait a bit to give the server a chance to come back online
              await new Promise((resolve) => setTimeout(resolve, Math.random() * 5000));

              socket.open((err) => {
                if (err) {
                  attemptReconnect();
                }
              });
            };

            attemptReconnect();
          });

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        // Clean data about last login
        removeAllData();
      }
    };
    initialize();
  }, [socket]);

  // Just on frist login connect in all
  useEffect(() => {
    const initialize = async () => {
      const accessToken = socketForce.token;
      const companyId = socketForce.user?.companyId;

      try {
        // Init Global Socket
        socket.auth.token = accessToken;
        socket.connect(`${SOCKET_URL}`, { auth: { token: accessToken }, withCredentials: true, autoConnect: true });
        // Connect to Company to recive mensages
        socket.on('connect', async () => {
          socket.emit('subscribe', companyId);
        });

        setForce({ v: false, token: null });
      } catch (err) {
        console.error(err);
      }
    };
    if (socketForce.v) {
      initialize();
    }
  }, [socket, socketForce]);

  const login = async (email, password, forceLogout = false) => {
    const response = await axios.post('/api/login', {
      email,
      password,
      forceLogout,
    });

    const { token, user } = response.data;

    // Set token before login
    axios.defaults.headers.Authorization = `Bearer ${token}`;
    // CheckCompany
    const companyRes = await axios.get(`/api/company/${user.companyId}`);

    if (companyRes.status) {
      user.company = companyRes.data;
    }

    setSession(token);
    window.localStorage.setItem('usr_id', user._id);

    // Init Global Socket
    setForce({ v: true, token, user });

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    window.localStorage.setItem('usr_id', user._id);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    const userId = window.localStorage.getItem('usr_id');
    await axios.get('/api/logout');
    socket.emit('immediate-disconnect', userId);
    socket.close();

    setSession(null);
    dispatch({ type: 'LOGOUT' });
    removeAllData();
  };
  // Clean data in storage
  const removeAllData = () => {
    setSession(null);
    window.localStorage.clear();
  };

  const update = (user) => {
    dispatch({
      type: 'UPDATE',
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        update,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
