/* eslint-disable react/prop-types */
import { forwardRef, useCallback } from 'react';
import QRCode from 'react-qr-code';

// import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar, SnackbarContent } from 'notistack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Iconify from './Iconify';

const ReportComplete = forwardRef(({ id, ...props }, ref) => {
  const { message, nameService } = props;
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <Card style={{ backgroundColor: '#fff' }}>
        <CardActions
          style={{ backgroundColor: '#415772', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="h6" color={'#fff'} fontWeight={700}>
            Autenticar - Tronco
          </Typography>
          <div>
            <IconButton size="small" onClick={handleDismiss}>
              <Iconify icon={'zondicons:close-outline'} color={'#fff'} />
            </IconButton>
          </div>
        </CardActions>
        <Typography variant="body1" color={'#000'} fontWeight={400} style={{ padding: 1 }}>
          Tronco <strong>{nameService}</strong>
        </Typography>
        {message !== undefined && (
          <QRCode
            size={250}
            style={{ height: 'auto', maxWidth: '100%', width: '100%', padding: 10 }}
            value={message}
            viewBox={`0 0 256 256`}
          />
        )}
      </Card>
    </SnackbarContent>
  );
});

export default ReportComplete;
