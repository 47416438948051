import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.message === 'Network Error') {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ error: 'Servidor não responde', message: 'Não foi possível se comunicar com o servidor' });
    }
    if (error.response.status === 401 || error.response.status === 403) {
      window.location = '/auth/login';
    }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
